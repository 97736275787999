import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Row from '../components/row/row';
import RenderBlock from '../components/renderBlock/renderBlock';

export const query = graphql`
  query($id: String!) {
    strapiPage(id: { eq: $id }) {
      title
      content
    }
  }
`;

const Page = ({ data }) => {
  const page = data.strapiPage;

  return (
    <Layout>
      <SEO title={page.title} description="" />
      <Row className="wysiwyg-content">
        <h1 dangerouslySetInnerHTML={{ __html: page.title }} />

        {page.content.map((block) => (
          <RenderBlock key={block.id} block={block} />
        ))}
      </Row>
    </Layout>
  );
};

export default Page;
